/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
 */



.page-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
  box-sizing: border-box;

  /* Responsive styles for tablet devices */
  @media (min-width: 768px) {
    max-width: 768px;
  }

  /* Responsive styles for desktop devices */
  @media (min-width: 1200px) {
    max-width: 1200px;
  }
}

.content {
  padding-top: 60px;
  /* Adjust the value to match the height of the navbar */
}

.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(59, 59, 59, 0.7);
  /* semi-transparent white background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  /* ensure it's above other elements */
}

#splash-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.whirling {
  animation: whirling 2s linear infinite;
}

@keyframes whirling {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.chat-info {
  display: flex;

  justify-content: space-between;
  align-items: center;
  /* Align items vertically in the center */
  padding: 10px;
  background: linear-gradient(to top, #3f3f3f, #5c5c5c);
  /* Dark gradient colors */

}


.user-avatar {
  flex: 0 0 auto;
  /* Keeps the avatar fixed in width */
  margin-right: 12px;
  /* Adjust the margin as needed */
}


.databubble {
  padding: 4px 8px;
  /* Adjust the padding as needed */
  background-color: #f0f0f0;
  /* Adjust the background color */
  border-radius: 4px;
  /* Add border radius for rounded corners */
  font-size: 12px;
  /* Adjust the font size */
  color: #333;
  /* Adjust the text color */
}

.switch-container {
  display: flex;
 /*  align-items: center;
  justify-content: center;
  flex-wrap: nowrap; */
}

.meet-card {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.modal-container {
  position: absolute;
  /* This is the containing block */
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  /* Set an appropriate z-index value to ensure it appears on top of other content */
  overflow: hidden;
}

.modal {
  position: fixed;
  /* Position relative to the .modal-container */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: linear-gradient(to bottom, #1a1a1a, #444444);
  /* Dark gradient colors */
  padding: 20px;
  width: 90%;
  /* Adjust as needed for your design */
  height: 90%;
  /* Adjust as needed for your design */
  z-index: 1000;
  overflow: hidden;
  /* This used to be auto */
}

.fullscreen-container {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  /* Set an appropriate z-index value to ensure it appears on top of other content */
  overflow: auto;
  max-width: 768px;
  margin: 0 auto;
}

.fullscreen-content {
  background: linear-gradient(to bottom, #1a1a1a, #444444);
  /* Dark gradient colors */
  padding: 20px;
  margin: 20px;

  box-sizing: border-box;
  /* max-width: 90%;
   Adjust as needed for your design 
  max-height: 80%; */
  /* Adjust as needed for your design */
  z-index: 1000;
  /* overflow: hidden;
   This used to be auto */
}

.meetpic-fullscreen-container {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  /* Set an appropriate z-index value to ensure it appears on top of other content */
  overflow: auto;
  max-width: 768px;
  margin: 0 auto;
}

.meetpic-fullscreen-content {
  background: linear-gradient(to bottom, #1a1a1a, #444444);
  /* Dark gradient colors */

  box-sizing: border-box;
  /* max-width: 90%;
   Adjust as needed for your design 
  max-height: 80%; */
  /* Adjust as needed for your design */
  z-index: 1000;
  /* overflow: hidden;
   This used to be auto */
}


/* USERPROFILE MODAL IN MEETCARD */
.userprofile-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  /* Stack children vertically */
  align-items: center;
  justify-content: center;
  z-index: 1000;
  margin: 0 auto;
  box-sizing: border-box;
  max-width: 768px;
}

.userprofile-content {
  padding: 20px;
  background: linear-gradient(to bottom, #1a1a1a, #444444);

  width: 100%;
  /* Ensure the content takes the full width */
  box-sizing: border-box;
  overflow: auto;
  min-height: 100vh; /* Set minimum height to viewport height */
  /* Hide content overflow */
}

/* SHOP PAGE CLASSES */

.gem-card {
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  margin: 10px;
  cursor: pointer;

}

.gem-card-content-old {
  width: 70%;
  height: auto;
  border-radius: 10px;

  cursor: pointer;
  animation: gemHover 1.5s infinite alternate;
  /* Use alternate to reverse the animation on each iteration */
}

.gem-card-container {
  width: 70%;
  margin: 20px auto;
  /* Adjust as needed */
  text-align: center;
  /* Center its children horizontally */
}

.gem-card-content {
  width: 100%;
  /* Use 100% width within the container */
  height: auto;
  border-radius: 10px;
  cursor: pointer;
  animation: gemHover 1.5s infinite alternate;
}

@keyframes gemHover {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.1);
  }
}

.affiliate-card-old {
  /* position: relative;
  width: 100%;
  height: 200px;
  margin: 10px;
  border-radius: 10px;
  cursor: pointer;  */

  position: relative;
  /* height: 200px; */
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.image-container-old {

  height: 100%;
  background-size: cover;
  background-position: center;
}

.affiliate-card {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

/* .image-container {
  max-width: 100%;
  display: block;
  margin: 0 auto;
  text-align: center; 
  
} */

.image-container {
  max-width: 100%;
  margin: 20px auto;  /* Top/bottom margin for spacing */
  padding: 10px 0;    /* Padding for internal spacing */
  text-align: center; /* Center text within container */
}

.image-container img {
  display: block;     /* Make image a block element */
  margin: 0 auto;     /* Center the image horizontally */
  max-width: 100%;    /* Ensure image doesn't overflow container */
}

.image-container .product-name {
  display: block;    /* Make product name a block element */
  margin-top: 10px;  /* Add some space between image and text */
}


.image {
  display: block; /* Remove extra space added by inline elements */
}

.gradient-overlay {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0.4));
  transform: translateY(-50%);
}

/* Add styles for text elements */
/* .product-name,
.price,
.company {
  position: relative;
  z-index: 1;
  
} */

/* .company-logo {
  position: absolute;
  top: 10px;
  left: 10px;
  height: 50px;
  border-radius: 5%;
} */

 .product-name {
  font-size: 15px;
  color: rgb(255, 255, 255);
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.842);
 
}
/*
.price {
  position: absolute;
  bottom: 0px;
  right: 10px;
  font-size: 40px;
  color: rgb(255, 255, 255);
 
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.842);
  
}


.company {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 18px;
} */

.info {

  color: #808080; /* Use a specific hex color for gray */
}


.fullscreen-mymodal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, #1a1a1a, #444444);
  /* Dark gradient colors */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  /* Set an appropriate z-index value to ensure it appears on top of other content */
  overflow: hidden;
  padding: 20px;
  max-width: 100%;
  /* Adjust as needed for your design */
  max-height: 80%;
  /* Adjust as needed for your design */
}

.meet-pic {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.button-container button {
  flex: 1; /* Allow buttons to share available space */
  max-width: 250px; /* Set maximum width if needed */
  min-width: 150px; /* Set minimum width to avoid collapsing */
  
}

.button-container small {
  text-align: center;
  margin-top: 10px;
  /* Optional margin above the text */
}

.meet-date-display-square {
  background-color: #dddddd;
  color: rgb(43, 43, 43);
  display: inline-block;
  /* Use inline-block to make each item its block */
  border-radius: 8px;
  padding: 18px 8px 8px;
  /* 26px top, 8px right and left, 8px bottom */
  max-width: fit-content;
  text-align: center;
  /* Center text within the square */
  position: absolute;
  top: 8px;
  left: 8px;
}

.chatinfo-display-square {
  background-color: rgba(54, 54, 54, 0.7);
  /* Adjust the alpha value as needed */
  /* color: rgb(241, 255, 45); */
  display: inline-block;
  /* Use inline-block to make each item its block */
  border-radius: 8px;
  padding: 10px;
  /* 26px top, 8px right and left, 8px bottom */
  max-width: fit-content;
  text-align: center;
  /* Center text within the square */
  position: absolute;
  top: 8px;
  left: 8px;
}

.meet-date-display-square::before {
  content: '';
  background-color: #d4006a;
  /* The color for the top part */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 16px;
  /* Adjust this value for the height of the colored part */
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}


.meet-pic-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  z-index: 1000;
  overflow: hidden;

}

.close-button {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(64, 64, 64, 0.7);
  /* Dark grey with 0.7 alpha for transparency */
  border: none;
  cursor: pointer;
  color: #ffffff;
  z-index: 1;
  display: inline-block;
  border-radius: 0px;
}

/* Notification/actions */

.notification-badge {
  position: absolute;
  top: 0;
  right: 0;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 5px;
  font-size: 12px;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}


/* ACTIONS */

/* Container for each action item */
.action-item {
  display: flex;
  align-items: center;        /* Vertically align avatar and content */
  padding: 10px;              /* Padding around each action item */
  border-bottom: 1px solid #ccc;  /* Border between items for separation */
}

.action-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: 0 20px 0 0;
  object-fit: cover; /* Ensures the image is cropped to fit the container */
}

/* Avatar wrapper to contain the avatar */
.avatar-wrapper {
  flex-shrink: 0;             /* Prevent avatar from shrinking */
  margin-right: 15px;         /* Space between avatar and content */
}

/* Content area of the action item */
.action-content {
  display: flex;
  flex-direction: column;     /* Stack text and timestamp vertically */
  justify-content: center;    /* Vertically center content */
}

/* Action content text */
.content-text {
  font-size: 16px;
  margin: 0;                  /* Remove default margin for paragraph */
}

/* Timestamp for how old the action is */
.timestamp {
  font-size: 12px;
  color: #888;                /* Grey color for the timestamp */
  margin-top: 5px;            /* Slight space above timestamp */
}

/* Nearby-view user preview screen */



.load-more-button {
  display: flex; /* Use Flexbox for the button */
  justify-content: center; /* Horizontally center the text */
  align-items: center; /* Vertically center the text */
  width: 100%; /* Make the button occupy the full width */
  height: 100%;
  /* ... your other button styles (padding, background, etc.) ... */
}

.nearby-actions-modal {
  position: absolute;
  /* This is the containing block */
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  /* Set an appropriate z-index value to ensure it appears on top of other content */
  overflow: hidden;
}


.nearby-user-button {
  margin: 8px;

  padding: 15px 10px;
  /* background: rgba(64, 64, 64, 0.7); */
  /* Dark grey with 0.7 alpha for transparency */
  /* border: none;
  cursor: pointer;
  color: #ffffff;
  z-index: 1;
  display: inline-block; */
  
}

.nearby-user-button-container {
  display: flex; /* Enable flexbox */
  justify-content: center; /* Center buttons horizontally */
  position: absolute; /* Make buttons absolute within the container */
  bottom: 0; /* Position at the bottom */
  left: 0; /* Align to the left edge */
  right: 0; /* Align to the right edge */
}

.meet-pic {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.error {
  color: #ff0000;
  /* This sets the text color to bright red */

  /* You can add more styles here if needed */
}

.description {
  /* Default styles for the description container */
 /*  max-height: 100px; 
  overflow: hidden; 
  transition: max-height 0.3s ease-in-out;  */
  text-align: center;
}

.description.expanded {
  /* Styles for expanded descriptions */
  max-height: unset; /* Allow full height */
}

.show-more {
  position: absolute;
  bottom: 5px; /* Position at the bottom with a small offset */
  right: 5px; /* Position at the right with a small offset */
  display: none;
}

.description:hover .show-more {
  /* Show button on hover */
  display: block;
}


.thumbnail {
  flex: 2;
  position: relative;
}

.thumbnail img {
  width: 100%;
  /* Make the image cover the entire container width */
  height: 256px;
  /* Make the image cover the entire container height */
  object-fit: cover;
  /* Crop and center the image to fill the container */
}

.chatbackground {

  flex: 2;
  position: relative;
}

.chatbackground img {
  width: 100%;
  /* Make the image cover the entire container width */
  height: 140px;
  /* Make the image cover the entire container height */
  object-fit: cover;
  /* Crop and center the image to fill the container */
}

.meetcard-buttonsection {
  display: block;
  width: 100%;
  height: 100%;
  text-align: center;
  /* Center the button text */

}

.security-icon {
  position: absolute;
  top: 10px;
  right: 10px;

}

.meetcard-info {
  flex: 3;
  padding: 10px;
  background: linear-gradient(to top, #3f3f3f, #5c5c5c);
  /* Dark gradient colors */

}

.date strong {
  text-transform: uppercase;
  font-size: larger;
  margin: 0;
  padding: 0;
}

.location h3 {
  text-transform: uppercase;
  margin: 0;
}

.tags {
  display: flex;
  position: absolute;
  bottom: 0px;
  left: 0px;
  flex-wrap: wrap;
  /* Allow flex items to wrap */
}

.tag {
  color: rgb(49, 49, 49);
  font-weight: bold;
  display: inline-block;
  border-radius: 20px;
  padding: 4px 12px 4px 12px;
  margin-left: 10px;
  margin-bottom: 10px;
  white-space: nowrap;
  /* Prevent text from wrapping to the next line */
  overflow: hidden;
  /* Hide text that overflows */
  text-overflow: ellipsis;
  /* Show an ellipsis (...) for long text */
  min-width: 0;
  /* Allow tags to shrink when needed */
}

/* LANDING PAGE CAROUSEL */

/* 
 .carousel-container {
  width: 95%; 
  padding: 0 10px; 
}*/
.carousel-slides {
  display: flex;
  transition: transform 0.5s ease-in-out;
}
.carousel-slide {
  flex: 0 0 100%;  
}
.carousel-slide img {
  width: 100%;
  height: auto;
}  

.carousel-container {
  width: 95%; 
  padding: 0 10px; 
  overflow-x: auto;  /* Enable horizontal scrolling */
  scrollbar-width: auto; /* Use the browser's default scrollbar width */
}

/* Style the scrollbar (optional) */
.carousel-container::-webkit-scrollbar {
  height: 10px;  /* Adjust the height of the scrollbar */
  background-color: #f5f5f5; /* Light background color */
}

.carousel-container::-webkit-scrollbar-thumb {
  background-color: #888; /* Darker color for the thumb */
  border-radius: 5px;  /* Rounded corners */
}


/* Add more specific styles based on your requirements */


.meet-article {
  background-image: url('https://wallpapers.com/images/high/grey-gradient-background-1920-x-1080-nhkxn5fc39z7npkn.webp');
  background-size: cover;
  background-repeat: no-repeat;

}

/* .chat-data-bubble {
  background-color: #ff0062;
  display: inline-block;
  border-radius: 20px;
  padding: 3px 11px 5px 11px;
  margin-left: 8px;
  margin-top: 4px;
  margin-bottom: 4px;
  font-size: smaller;
}

.chat-yourturn-bubble {
  background-color: #1adb00;
  display: inline-block;
  border-radius: 20px;
  padding: 3px 11px 5px 11px;
  margin-left: 8px;
  margin-top: 4px;
  margin-bottom: 4px;
  font-size: smaller;
} */

.chat-data-bubble {
  background-color: #c8004e; /* Darker pink */
  color: white; /* White text */
  display: inline-flex; /* Use inline-flex for alignment */
  align-items: center; /* Vertically center content */
  justify-content: center; /* Horizontally center content */
  border-radius: 20px;
  padding: 5px 10px; /* Adjust padding for circle appearance */
  margin-left: 8px;
  margin-top: 4px;
  margin-bottom: 4px;
  font-size: smaller;
  min-width: 30px; /* Set a minimum width to create a circle */
  min-height: 30px; /* Set a minimum height to create a circle */
}

.chat-yourturn-bubble {
  background-color: #159c00; /* Darker green */
  color: white; /* White text */
  display: inline-flex; /* Use inline-flex for alignment */
  align-items: center; /* Vertically center content */
  justify-content: center; /* Horizontally center content */
  border-radius: 20px;
  padding: 5px 10px; /* Adjust padding for circle appearance */
  margin-left: 8px;
  margin-top: 4px;
  margin-bottom: 4px;
  font-size: smaller;
  min-width: 30px; /* Set a minimum width to create a circle */
  min-height: 30px; /* Set a minimum height to create a circle */
}

.date-row {
  padding: 5px;
  /* Add some spacing between rows */
  display: inline-block;
  /* Display rows in a row */
}

/* .my-container {
  font-family: 'Roboto', sans-serif;
  margin: 0 auto;
  padding: 0 1rem; 
  max-width: 768px;

  @media (min-width: 768px) {
    max-width: 768px;
  }
} */

.page-container {
  font-family: 'Roboto', sans-serif;
  margin: 0 auto;
  padding: 0 1rem; 
  top: 0;
  bottom: 0;

}

.prelogin-container {
  font-family: 'Roboto', sans-serif;
  margin: 0 auto;
  padding: 0 1rem; 

}

.my-container {
  margin: 0 auto;
  padding: 0; /* Reset padding */
  @media (min-width: 768px) {
    max-width: 768px;
  }
}




/* MAIN NAVBAR */

.main-navbar {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  padding-left: 1rem;
  padding-right: 1rem;
  box-sizing: border-box;


  background-color: #11191f;
  outline: none;
  /* Remove focus outline for the entire navbar */

  /* Make sure it inherits the width from its parent */
  max-width: 768px;
  width: 100%;

  /* Responsive styles for tablet devices */
  @media (min-width: 768px) {
    max-width: 768px;
  }

  /* Responsive styles for desktop devices */
  @media (min-width: 768px) {
    max-width: 768px;
  }
}

.context-navbar {
  position: sticky;
  top: 0;
  /* left: 0;
  right: 0; */
  padding: 0px;
  display: flex;
  justify-content: space-between;
  background-color: #11191f;
  z-index: 10;
}

.main-navbar2 {
  position: sticky;

  bottom: 0;
  /* left: 0;
right: 0; */
  display: flex;
  justify-content: space-between;
  background-color: #11191f;
  z-index: 10;
}

.main-navbar *:focus {
  outline: none;
  /* Remove focus outline for all elements within the navbar */
}

.main-navbar .nav-link:focus {
  background-color: transparent !important;
}

/* CHAT */

/* .no-scroll-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 50%;

  transform: translateX(-50%);


  background-color: transparent;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: 100vh;
  
  width: 100%;
  padding-bottom: 120px;
  max-width: 768px;
}

.message-container {

  display: flex;
  flex-direction: column;
  padding: 10px;
  overflow-y: auto;
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
 background-color: transparent;
  

  height: calc(100vh - 180px);
 
}

.chat-infotext-container {
  padding: 5px;
  text-align: center;
}

.chat-top-navbar {
  position: sticky;
  left: 0;
  right: 0;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  background-color: transparent; 
 
  z-index: 10;
  top: 0;
  box-sizing: border-box;
  height: 60px;
}

.chat-top-navbar .nav-link:focus {
  background-color: transparent !important;
  outline: none !important;
}

.chat-bottom-navbar {
  position: absolute;
  left: 0;
  right: 0;
  padding: 10px;
  padding-bottom: 120px;
  display: flex;
  height: 120px;
  justify-content: space-between;
  z-index: 10;
  bottom: 0;
}

.chat-bottom-navbar .nav-link:focus {
  background-color: transparent !important;
  outline: none !important;
} */

.no-scroll-container {

  display: flex;
  flex-direction: column;
  height: 100vh;
  box-sizing: border-box;
  background-color: transparent;
  
}

.chat-top-navbar {
  padding-right: 10px;
  display: flex;
  justify-content: space-between;
  background-color: rgba(0, 0, 0, 0.8); /* Black with 20% opacity */
  
  box-sizing: border-box;
  height: 60px;
  position: fixed;
  top: 0;
  width: 100%; 
  
  left: 0;
  right: 0; 

  z-index: 1; /* Ensure it's above the message container */
}



.chat-bottom-navbar {
  padding: 5px;
  display: flex;
  justify-content: space-between;
  background-color: transparent;
  box-sizing: border-box;
  /* background-color: rgba(0, 0, 0, 0.8);  */
  background-color: transparent;
  height: 80px;
  position: sticky;
  bottom: 0;
  z-index: 1; /* Ensure it's above the message container */
  left: 0;
  right: 0;

}

 .textarea {
  flex: 1;
  resize: vertical;
  word-wrap: break-word;
  
  height: 100%;
} 

.send-error-message {
  color: red;
  margin-top: 10px;
}

.send-button {
  padding-left: 5px;
  list-style: none;
  background-color: transparent;
  color: white;
  cursor: pointer;
  border: none;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none !important;
}

.message-container {
  margin-top: 60px;
  flex: 1;
  overflow-y: auto;
  padding: 10px;
  box-sizing: border-box;
  background-color: transparent;

  margin-bottom: 80px;
  /*max-height: calc(100vh - 180px);  Adjust the height as needed */
}

.sender-image-container {
  width: 50px;
  /* Set a fixed width for the container */
  height: 50px;
  /* Set a fixed height for the container */
  overflow: hidden;
  /* Hide any content that might overflow */
  
}

.sender-image {
  width: 100%;
  /* Make the image fill the container */
  height: 100%;
  /* Ensure the image takes up the full height of the container */
  object-fit: cover;
  /* Ensure the image covers the entire container */
  border-radius: 50%;
  /* For a circular image; adjust as needed */
  display: block;
  /* Ensure proper rendering as a block element */
  margin: 0;
  /* Remove any default margin */
  padding: 10px;
  
}

.sender-image-container-old {
  width: 50px;            /* Fixed width for the container */
  height: 50px;           /* Fixed height for the container (same as width) */
  overflow: hidden;       /* Hide any content that might overflow */
  padding-left: 10px;     /* Padding on the left side */
  padding-right: 10px;    /* Padding on the right side */
  border-radius: 50%;     /* Make the container a perfect circle */
  display: flex;          /* Use flexbox for centering */
  justify-content: center;/* Horizontally center the image */
  align-items: center;    /* Vertically center the image */
}

.sender-image-old {
  width: 100%;           /* Make the image fill the container width */
  height: auto;           /* Let the height adjust automatically to maintain aspect ratio */
  object-fit: cover;      /* Ensure the image covers the entire container */
  border-radius: 50%;     /* Inherit the circular shape from the container */
}


.message-wrapper {
  display: flex;
  word-wrap: break-word;
  border-radius: 10px;
  padding: 10px;
  max-width: 80%;
  /* Set a maximum width for the message wrapper */
  flex-grow: 1;
}

.own-message-bu {
  background: linear-gradient(to right, #00008b, #0000ff);
  /* Additional styles for .own-message */
}

.other-message-bu {
  background: linear-gradient(to right, #aa336a, #ff4fa1);
  /* Additional styles for .other-message */
}

.own-message {
  background: linear-gradient(to bottom left, #00008b, #0000ff); /* Diagonal gradient */
  color: white; /* White text */
  /* Additional styles for .own-message */
}

.other-message {
  background: linear-gradient(to top left, #aa336a, #ff4fa1); /* Diagonal gradient */
  color: white; /* White text */
  /* Additional styles for .other-message */
}


/* For messages sent by the user (own-message) */
.message-wrapper.own-message {
  justify-content: flex-start;
  /* Align content to the right for own messages */
}

.message-wrapper.other-message {
  justify-content: flex-start;
  /* Align content to the right for own messages */
}



.message-content {
  display: flex;
  align-items: flex-start;
  margin-bottom: 7px;
}

/* .message-bubble {
  word-wrap: break-word;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 7px;
  max-width: 100%;
} */

.textarea-container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: transparent;
  max-width: 768px;


}





/* New landing page testimonials */

.testimonial-container {
  display: flex;
  flex-wrap: wrap; /* Allows testimonials to wrap on smaller screens */
  justify-content: space-between; /* Even spacing between testimonials */
  margin: 20px 0;
}
.testimonial {
  display: flex;
  align-items: center;
  padding: 0px;
  /* border: 1px solid #ddd; 
  border-radius: 5px;*/
  margin-bottom: 25px;
}

.avatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin: 0 20px 0 0;
}
.avatar:nth-child(even) {  /* Style right-aligned avatars */
  margin: 0 0 0 20px;  /* Adjust margins for right-aligned avatars */
}
.quote {
  font-style: italic;
  font-size: 16px;
}


  



/* UNIVERSAL CONFIRM dialog */

.universal-dialog {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* Center items horizontally */
  overflow-y: auto;

}

.button-container {
  display: flex;
  justify-content: space-between;
  /* Space buttons evenly */
  width: 100%;
  /* Make sure the buttons take the full width of the container */
  margin-top: 10px;
  /* Add some top margin for spacing */
}

/* PAGE SPECIFIC TOP NAVBAR */

.segmented-control {
  display: flex;
  justify-content: space-between;
  background-color: #f0f0f0;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.segmented-control-button {
  flex: 1;
  text-align: center;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.segmented-control-button:hover {
  background-color: #ddd;
}

.segmented-control-button:focus {
  outline: none;
}

.segmented-control-button.active {
  background-color: #007bff;
  /* Active button background color */
  color: #fff;
  /* Active button text color */
}

.segmented-control-container {
  position: relative;
  overflow: hidden;
  transition: height 0.3s ease-in-out;
  height: 60px;
  /* Adjust the initial height as needed */
}

.segmented-control-hidden {
  height: 0;
  overflow: hidden;
}

.red-icon {
  color: red; /* Change 'fill' to the appropriate property for your icon styling */
}

@keyframes pulse {
  0% {
    transform: scale(1);
    filter: brightness(1.3);
  }

  50% {
    transform: scale(1.5);
    filter: brightness(1.8);
  }

  100% {
    transform: scale(1);
    filter: brightness(1.3);
  }
}


.pulse-animation {
  animation: pulse 5s infinite
}

/* Tabs for meets view */
.tabs {
  display: flex; /* Arrange buttons side-by-side */
  gap: 5px;
}

.tabs button {
  flex: 1; /* Distribute buttons equally within the container */
  /* padding: 10px 10px; Adjust padding as needed */
 

 
  text-align: center; /* Center text within buttons */
}

.tabs button.active {

  pointer-events: none;
}

.tabs button:not(.active) {
  opacity: 0.5; /* Reduce opacity for inactive buttons (disabled look) */
 
}



/* SWIPER */

.swiper {
  width: 100%;
  height: auto;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* FRONT PAGE CSS */


/* .hero {
  font-family: 'Poppins', sans-serif !important;
  background-color: #394046;
  background-image: url("assets/cover.jpg");
  background-position: center;
  background-size: cover;
  min-height: 40vh;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
} */

.frontpage-container {
  font-family: 'Poppins', sans-serif !important;

}

header {
  padding: var(--spacing-company) 0;
}

header hgroup> :last-child {
  color: var(--h3-color);
}

header hgroup {
  margin-bottom: var(--spacing-company);
}

/* Nav */
summary[role="link"].contrast:is([aria-current], :hover, :active, :focus) {
  background-color: transparent;
  color: var(--contrast-hover);
}

/* In your CSS file or style block */

.centered-link-old {
  text-align: center;
  /* position: absolute;
  bottom: 10px;
   left: 50%;
  transform: translateX(-50%); */
}

.centered-link {


  text-align: center;
  /* Center the content horizontally */
}

.centered-link a {

  text-decoration: none;
  padding: 8px 16px;
  /* Adjust padding as needed */

  border-radius: 5px;

  display: inline-block;
}

/* Social media icons */

.social-media-row {
  display: flex;
  align-items: center;
}

.social-media-row a {
  margin-left: 10px;
  color: inherit;
  text-decoration: none;
}

.social-media-row i {
  font-size: 40px;
}

/* Account view options */

.text-color-same-as-icon {
  color: inherit; /* Inherit color from parent element (IconUserEdit) */
}

/* Nearby-näkymä */

.nearby-container {
  margin: 5px;
}





.user-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr)); /* Adjust minmax values for desired width range */
  gap: 5px;
  /*gap: 5px;
   grid-template-rows: 150px; Shorthand for a single row with fixed height */
}

.user-card {
  /* ... other styles */
  /* overflow: hidden;  Clip overflowing content */
  display: flex; /* Enable flexbox layout */
  flex-direction: column;
  position: relative; /* Required for absolute positioning */
  max-width: 28vw;
}

.star-icon {
  position: absolute;
  right: 3px;
  top: 3px;

}

.user-card img {
  width: 100%; /* Fills the available width of the card */
  
  height: 170px; /* Fixed height of 100px */
  object-fit: cover; /* Still required for cropping */
  /* Additional properties for aspect ratio and cropping */
  position: relative; /* Required for absolute positioning */
  top: 50%; /* Position image vertically at the center */
  transform: translateY(-50%); /* Adjust vertical positioning as needed */
  border-radius: 15px;
}


/* .user-info {
  
  bottom: 0; 
  left: 0; 
  padding: 3px; 
  background-color: rgba(0, 0, 0, 0.3); 
  color: #fff; 
} */

.user-info {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 2px 10px; /* Adjust padding as needed */
  background-color: rgba(0, 0, 0, 0.4); /* Semi-transparent overlay */
  color: #fff; /* White text (default) */
  display: flex; /* Enable flexbox for layout */
  flex-direction: column; /* Stack elements vertically */
  justify-content: space-between; /* Distribute content vertically with some space */
}

.user-info span:first-child { /* Target first span (name and age) */
  font-weight: bold; /* Bold font weight for name */
}

.user-info span:last-child { /* Target second span (distance) */
  color: #d8d8d8; /* Light grey color for distance */
  font-size: 0.8em; /* Smaller font size for distance */
}




.stars {
  cursor: pointer;
  display: inline-block;
  margin-top: 5px;
}

.like-icon {
  position: absolute;
  top: 5px;
  right: 5px;
}

/* Assuming you have styles for IconStar and IconStarFilled components */
.stars i,
.like-icon i {
  font-size: 20px;
  color: #ccc;
}

.user-card.liked .stars i,
.like-icon i {
  color: #f00; /* Optional styling for star color on liked users */
}


/* Main */
@media (min-width: 992px) {
  main .grid {
    grid-column-gap: var(--spacing-company);
    grid-template-columns: auto 25%;
  }
}

form.grid {
  grid-row-gap: 0;
}

/* Aside nav */
aside img {
  margin-bottom: 0.25rem;
}

aside p {
  margin-bottom: var(--spacing-company);
  line-height: 1.25;
}

/* Blue-grey Light scheme (Default) */
/* Can be forced with data-theme="light" */
[data-theme="light"],
:root:not([data-theme="dark"]) {
  --primary: #546e7a;
  --primary-hover: #455a64;
  --primary-focus: rgba(84, 110, 122, 0.125);
  --primary-inverse: #FFF;
}



/* Blue-grey Dark scheme (Auto) */
/* Automatically enabled if user has Dark mode enabled */
@media only screen and (prefers-color-scheme: dark) {
  :root:not([data-theme]) {
    --primary: #546e7a;
    --primary-hover: #607d8b;
    --primary-focus: rgba(84, 110, 122, 0.25);
    --primary-inverse: #FFF;
  }
}

/* Blue-grey Dark scheme (Forced) */
/* Enabled if forced with data-theme="dark" */
[data-theme="dark"] {
  --primary: #546e7a;
  --primary-hover: #607d8b;
  --primary-focus: rgba(84, 110, 122, 0.25);
  --primary-inverse: #FFF;
}

/* Blue-grey (Common styles) */
:root {
  --form-element-active-border-color: var(--primary);
  --form-element-focus-color: var(--primary-focus);
  --switch-color: var(--primary-inverse);
  --switch-checked-background-color: var(--primary);
}